.point-list {
  padding: 0px;
  .point-list-item {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
  .active {
    border-left-style: solid;
    border-color: rgb(255, 255, 255);
  }
  .point-list-item:hover {
    background-color: rgba(255, 255, 255, 0.171);
  } 
}
