.category-chips {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  .chip {
    background-color: rgba(0, 0, 0, 0.13);
    margin-right: 12px;
    margin-right: 12px;
    border-radius: 19px;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    margin-bottom: 7px;
    height: 38px;
    div {
      float: left;
      display: inherit;
    }
    img {
      width: 38px;
      height: 38px;
      border-radius: 19px;
    }
    h5 {
      display: inline;
      padding: 8px 12px;
    }
  }
}
