.cart-order-selected {
  background: rgba(0, 0, 0, 0.171);
}
.cart-order {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-order {
  font-size: 35px;
}
