.ape-kiosk-card-list {
  margin: 20px;
  color: rgb(255, 255, 255);
  flex-grow: 1;
  overflow-y: auto;
  .list-item {
    border-radius: 10px;
    width: 150px;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.13);
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    position: relative;
    .image {
      margin: 10px 10px;
      width: 130px;
      height: 130px;
      img {
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 1.3em;
      font-weight: bold;
      margin-left: 10px;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      color: rgba(255, 255, 255, 0.71);
      margin-left: 10px;
      margin-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .options {
      position: absolute;
      width: 150px;
      height: 200px;
      font-size: 20px;
      // color: rgba(214, 55, 95, 0.739);
      // .price {
      //   right: 0;
      //   bottom: 0;
      //   margin-right: 25px;
      //   margin-bottom: 25px;
      //   font-size: 40px;
      //   display: inline;
      //   position: absolute;
      // }
      .icons {
        right: 0;
        bottom: 0;
        margin-right: 15px;
        margin-bottom: 15px;
        display: inline;
        position: absolute;
      }
    }
  }
  .list-item:hover, .list-item-select {
    background-color: rgba(36, 29, 29, 0.376); 
  }
}
