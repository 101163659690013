.cart-items {
  display: grid;
}
.cart-total {
  text-align: right;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 28px;
  font-weight: bold;
}
.cart-total-original {
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
}
.cart-item {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 7, 14, 0.308);
  margin: 0;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  .cart-item-container {
    flex: 1;
    .cart-item-name {
      padding-top: 10px;
      padding-bottom: 5px;
      font-size: 25px;
      font-weight: bold;
    }
    .cart-item-detail {
      font-size: 17px;
      color: rgba(255, 255, 255, 0.541);
    }
  }
  .cart-item-total {
    width: 170px;
    text-align: right;
    div {
      font-size: 25px;
      font-weight: bold;
      padding-top: 10px;
    }
    small {
      font-size: 12px;
    }
  }
  .cart-item-icon {
    width: 50px;
    padding-top: 15px;
    text-align: right;
  }

  .image {
    width: 64px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.cart-item-select {
  background-color: rgba(255, 255, 255, 0.15);
}
.cart-item:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
