.screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.toolbar {
  width: 250px;
  flex-shrink: 0;
  overflow-y: auto;
}
.container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.content-container {
  flex-grow: 1;
  overflow-y: auto;
}
.notifier {
  width: 250px;
  flex-shrink: 0;
  overflow-y: auto;
}
.icon-toolbar {
  width: 64px;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: rgba(22, 22, 22, 0.87);
}
.summary {
  width: 600px;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: rgba(22, 22, 22, 0.5);
}
.screen {
  // background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}
.toolbar {
  background-color: rgba(22, 22, 22, 0.87);
}
.container {
  // background-color: rgb(22, 22, 22);
  background-color: rgba(22, 22, 22, 0.048);
}
.notifier {
  // background-color: rgb(42, 42, 42);
  background-color: rgba(22, 22, 22, 0.5);
}
