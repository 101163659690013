.ape-kiosk-card-list {
  margin: 20px;
  color: rgb(255, 255, 255);
  flex-grow: 1;
  overflow-y: auto;
  .list-item {
    width: 200px;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.13);
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    .image {
      margin: 20px;
      width: 160px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 1.3em;
      font-weight: bold;
      margin-left: 20px;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      color: rgba(253, 253, 253, 0.322);
      margin-left: 20px;
      margin-right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .options {
      position: absolute;
      width: 200px;
      height: 250px;
      font-size: 20px;
      // color: rgba(214, 55, 95, 0.739);
      .price {
        right: 0;
        bottom: 0;
        margin-right: 25px;
        margin-bottom: 25px;
        font-size: 40px;
        display: inline;
        position: absolute;
      }
      .icons {
        right: 0;
        bottom: 0;
        margin-right: 15px;
        margin-bottom: 15px;
        display: inline;
        position: absolute;
      }
    }
  }
  .list-item:hover, .list-item-select {
    background-color: rgba(36, 29, 29, 0.376); 
  }
}
