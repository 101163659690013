.app-bg {
  background: url('../../assets/images/pexels-ricky-esquivel-1745747.jpg')  no-repeat center center fixed;
  // background: url('../../assets/images/pexels-la-miko-3754594.jpg')  no-repeat center center fixed;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}
.home-title {
  h1 {
    text-align: center;
    font-size: 80px;
    font-weight: bold;
  }
  p {
    text-align: center;
    font-size: 1.3em;
  }
}
